
// import TableList from "./TableList.vue";
import Buttons from "../../Commons/Buttons.vue";
import SnackBar from "../../Commons/SnackBar.vue";
import Vue from "vue";
import {
	Advertiser,
	AdvertiserFilters,
} from "../../../../interfaces/advertiser";
import {
	SortingOption,
	Paginated,
	ResultPaginate,
} from "../../../../interfaces/paginated";
import { isEmpty, isNull, isUndefined } from "lodash";
import omit from "lodash/omit";
import ParamService from "../../../../services/params-service";
import { mapActions, mapGetters } from "vuex";
import {
	prepareSelectedData,
	prepareTableData,
	preparedColumnDefsGrid,
	prepareTableContent,
} from "../../../../utils/CustomizeViewData";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue";
// @ts-ignore
import CustomizeRowGrid from "@/components/Header/Tables/CustomizeRowGrid.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import { getPermisionAdvertisers } from "@/utils/permissionResolve.ts";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "AdvertiserList",
	props: {},
	components: {
		Buttons,
		Alertize,
		SnackBar,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
		CustomizeRowGrid,
		NotPermission
	},
	data: function () {
		return {
			title: "List",
			paginated: {
				page: 1,
				limit: 25,
			} as Paginated,
			filters: {},
			options: getDefaultSortingOption(),
			table: {
				headers: [],
				items: [],
			},
			fields: {},
			min: 0,
			max: 100,
			slider: 40,
			columnRestored: false,
			context: null,
			selectionRows: "single",
		};
	},
	created() {
		this.$nextTick(async () => {});
	},
	async mounted() {
		await this.verifyFilters();
		await this.getPaginated();
		this.context = { componentParent: this };
	},
	computed: {
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),
		...mapGetters("profile", ["isRolReport", "getAbility"]),
		...mapGetters("customizer_filter", ["getFiltersEntity"]),
		...mapGetters("internationalization", ["getLanguage"]),

		getResultPaginate(): ResultPaginate {
			return this.$store.state.advertiser.result_paginate;
		},

		getAdvertisers(): Advertiser[] {
			const result: ResultPaginate = this.getResultPaginate;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},

		getSizeAdvertiser() {
			return this.$t("show.advertisers", {
				total: this.getAdvertisers.length,
			});
		},

		getConfig() {
			return {
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
				fields: this.fields,
			};
		},

		getConfigColumnDef() {
			return {
				context: this.context,
				eventActive: false,
				entityName: "Advertiser",
				redirect: "AdvertiserEdit",
				activityLog: "",
				edit: true,
				duplicated: false,
				log: false,
				minWidthActions: 80,
				maxWidthActions: 80,
				permission: this.getPermission
			};
		},

		getFiltrosAplicados() {
			const newFilters =
				this.getFiltersEntity("AdvertiserFilter").filters;
			return Object.keys(newFilters).length > 0 ? newFilters : {};
		},

		getOptionsAplicados() {
			return this.getFiltersEntity("AdvertiserFilter").options;
		},

		getColumnsDef() {
			return preparedColumnDefsGrid(
				this.table.headers,
				this.getConfigColumnDef
			);
		},

		gerRowsData() {
			if (isEmpty(this.table.items)) {
				return [];
			} else {
				return this.table.items;
			}
		},

		canList(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionAdvertisers();
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		/** Begin Customize View **/

		...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected",
		]),

		...mapActions("customizer_filter", [
			"saveFiltersEntity",
			"resetFilterEntity",
		]),

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},

		async handleUpdateSizeColumns(params: any) {
			params.forEach((element: { col: any; actualWidth: any }) => {
				let col = this.table.headers.filter(
					(c: { value: any }) => c.value == element.col
				);
				col[0].reactive_width = element.actualWidth;
				col[0].width = String(element.actualWidth) + "px";
			});
			let event = {
				view: params.entity,
			};
			await this.saveColumns({ event, columns: this.table.headers });
		},

		async verifyFilters() {
			const newFilters = this.getFiltrosAplicados;
			if (Object.keys(newFilters).length < 1) return;
			this.filters = newFilters;
			this.options = this.getOptionsAplicados;
		},

		async handleSave(event: any) {
			await this.saveSelected(event);
			await this.saveColumns({ event, columns: this.table.headers });
			await this.getTableData(event);
		},

		async updateItemsTable() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);

			this.fields = await prepareSelectedData(selected);

			this.table.items = await prepareTableContent(
				this.fields,
				this.getAdvertisers
			);
			this.$forceUpdate();
		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {
			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getAdvertisers,
			});

			this.fields = fields;

			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();

			this.table = await prepareTableData({
				columns: columns,
				fields: this.fields,
				entity: "advertisers",
				entities: this.getAdvertisers,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.table.headers });
				//this.columnRestored = event?.isReset
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

		/** End Customize View **/

		async getPaginated(uTable: boolean = true) {
			if(this.canList){
				await this.setLoadingData(TypeLoading.loading);
				await this.$store.dispatch(
					"advertiser/paginated",
					await ParamService.getParams(
						this.paginated,
						this.filters,
						this.options
					)
				);
				if (uTable) {
					await this.getTableData({ view: this.$route?.name });
				} else {
					await this.updateItemsTable();
				}
				await this.setLoadingData();
			}
		},

		updatePaginate(data: any) {
			this.paginated.page = data;
		},

		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] =
				typeof params.value !== "undefined" ? params.value : "";
			await this.saveFilters();
		},

		async selectedOption(params: { options: SortingOption; filter: any }) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			this.updatePaginate(1);
			await this.updateParams(
				{
					filters: this.filters,
					options: params.options,
				},
				false
			);
		},

		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated(false);
		},

		async updateParams(
			params: {
				filters: AdvertiserFilters;
				options: SortingOption;
			},
			uTable: boolean = true
		) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated(uTable);
		},

		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption();
			await this.resetFilter();
			this.updatePaginate(1);
			await this.getPaginated(false);
		},

		async saveFilters() {
			this.saveFiltersEntity({
				view: "AdvertiserFilter",
				filters: { filters: this.filters, options: this.options },
			});
		},

		async resetFilter() {
			this.resetFilterEntity({
				view: "AdvertiserFilter",
				filters: { filters: {}, options: {} },
			});
		},

		async removeKeyFilter(key: string) {
			const newFilters: any = omit(this.filters, [key]);
			await this.updateParams(
				{
					filters: newFilters,
					options: this.options,
				},
				false
			);
			await this.saveFilters();
		},

		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated();
			}
		},
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
	},
});
